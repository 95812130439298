import img1 from '../../assets/img1.png';
import img2 from '../../assets/img2.png';
import img3 from '../../assets/img3.png';


export const SliderProducts = [{
    id:1,
    name: 'SKIN',
    detail: 'Super Skin Care',
    price: '25',
    img: img1
},
{   id:2,
    name: 'SKIN',
    detail: 'Super Skin Care',
    price: '30',
    img: img2
},
{   id:3,
    name: 'NATURE',
    detail: 'Super Skin Care',
    price: '25',
    img: img3
}
]
