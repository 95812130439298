import React from 'react';

import Home from './components/Home';
import "./main.css";




function App() {
  return (

    <>

    <Home/>

    </>
          
  );
  
  }

export default App;
