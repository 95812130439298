import img1 from '../../assets/img1.png';
import img10 from '../../assets/img10.png';
import img11 from '../../assets/img11.png';
import img12 from '../../assets/img12.png';
import img13 from '../../assets/img13.png';
import img14 from '../../assets/img14.png';
import img2 from '../../assets/img2.png';
import img3 from '../../assets/img3.png';
import img4 from '../../assets/img4.png';
import img5 from '../../assets/img5.png';
import img6 from '../../assets/img6.png';
import img7 from '../../assets/img7.png';
import img8 from '../../assets/img8.png';
import img9 from '../../assets/img9.png';

export const ProductsData = [{
    id:1,
    name: 'SKIN',
    detail: 'Super Skin Care',
    price: '25',
    img: img1,
    type: 'skin care',
},
{   id:2,
    name: 'SKIN',
    detail: 'Super Skin Care',
    price: '30',
    img: img2,
    type: 'skin care',
},
{   id:3,
    name: 'NATURE',
    detail: 'Super Skin Care',
    price: '25',
    img: img3,
    type: 'skin care',
},
{   id:4,
    name: 'Foundation',
    detail: 'Super Skin Care',
    price: '25',
    img: img4,
    type: 'foundation',
},
{   id:5,
    name: 'CONDITIONER',
    detail: 'Super Skin Care',
    price: '30',
    img: img5,
    type: 'conditioner',
},
{   id:6,
    name: 'NATURE',
    detail: 'Super Skin Care',
    price: '25',
    img: img6,
    type: 'skin care',
},
{   id:7,
    name: "CONDITIONER",
    details: 'Best Conditioner',
    price: '30',
    img: img7,
    type: 'conditioner'
}
,
{   id:8,
    name: "CONDITIONER",
    details: 'Best Conditioner',
    price: '30',
    img: img8,
    type: 'conditioner'
},
{   id:9,
    name: "CONDITIONER",
    details: 'Best Conditioner',
    price: '30',
    img: img9,
    type: 'conditioner'
},
{   id:10,
    name: "CONDITIONER",
    details: 'Best Conditioner',
    price: '30',
    img: img10,
    type: 'conditioner'
},
{   id:11,
    name: "FOUNDATION",
    details: 'Nourish your skin',
    price: '12',
    img: img11,
    type: 'foundation'
},
{   id:12,
    name: "FOUNDATION",
    details: 'Nourish your skin',
    price: '12',
    img: img12,
    type: 'foundation'
},
{   id:13,
    name: "FOUNDATION",
    details: 'Nourish your skin',
    price: '12',
    img: img13,
    type: 'foundation'
},
{   id:14,
    name: "FOUNDATION",
    details: 'Nourish your skin',
    price: '12',
    img: img14,
    type: 'foundation'
}

]